.contact-form {
  
    padding: 2rem;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #d72083;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-form__group {
    display: flex;
    flex-direction: column;
  }
  .contact-form__group select {
    padding: 1rem;
    outline: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .contact-form__group select option {
    padding: 0.5rem 1rem; /* Increased specificity */
    background-color: #fff;
  }
  
  .contact-form__group select option:hover {
    background-color: #f2f2f2; /* Optional hover effect */
  }
  .contact-form__group label {
    font-weight: bold;
    margin-bottom: 0.5rem;

  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    font-family: roboto , sans-serif;
  }
  
  .contact-form button {
    padding: 1rem 2rem;
    background-color: #EA2A91;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #d72083;
  }
  
  .status{
    color: #d72083;
    text-align: center;
    font-weight: 400;
    padding: 10px 15px 10px 15px;
    font-family: Roboto, sans-serif;
    margin-top: 10px;
  }