.header_container{
    border-bottom: 4px solid #EA2A91;
    margin: 0px 5% 10px 5%;
    padding: 10px 30px 10px 30px;
    display:flex;
    justify-content: space-between;
}
.header_container .section1{
    flex:1;
    font-family: "Anton", sans-serif;
    font-size: 36px;
    color:#EA2A91;    
    display: flex;
    align-items: center;
}
.header_container .section2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex:3;
}
.header_container .section2 a li{
    list-style: none;
    font-size: 20px;
    color:#1E1E1E;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 250;
}
.header_container .section2 a{
    text-decoration: none;
}
.header_container .section3{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}
.header_container .section3 a{
    text-decoration: none;
}
.header_container .section3 a button{
    background-color: #EA2A91;
    color:#fff;
    border: none;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    transition: .3s all;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: bottom;
}
.header_container .section3 a button:hover{
    background-color: #db0b7a;
    transition: 1s all;
}
.mobile_header_links{
    display: none;
}
@media (max-width:1170px){
    .header_container .section1{
        font-size: 24px;
    }
    .header_container .section2{
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        flex:2;
    }
    .header_container .section2 a li{
        list-style: none;
        font-size: 15px;
        color:#1E1E1E;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 250;
    }
    .header_container .section3 a button{
        font-size: 15px;
        padding: 7px 15px 7px 15px;
        border-radius: 2px;
        transition: .3s all;
        display: flex;
        justify-content: center;
        align-items: bottom;
    }
    
}

@media (max-width:730px){
    .header_container{
        padding: 8px 15px 8px 15px;
        border-bottom: none;
    }
    .header_container .section2{
        display: none;
    }
    .header_container .section1{
        flex:0;
    }
    .mobile_header_links{
        margin: 0px 5% 2px 5%;
        display: block;
        list-style: none;
        font-size: 12px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #EA2A91;
        border-bottom: 2px solid #EA2A91;
        padding: 5px 10px 5px 10px;
    }
    .mobile_header_links a {
        text-decoration: none;
        color:#1E1E1E;
    }
}