.footer_img img{
    width: 100%
    ;
}
.footer_img{
    width: 100%;

}
.footer{
    background-color: #540C33;
    padding-bottom: 10px;
}
.footer_bottom{
    color: #fff;
    background-color: #540C33;
    margin-top: 70px;
    
    display: flex;
    padding: 2% 5% 0% 5%;
}
.footer_bottom .footer_left h2{
    font-family: Antonio, sans-serif;
    font-size: clamp(1rem, 2vw , 3rem);
}
.footer_bottom .footer_left h3 {
    font-family: Antonio, sans-serif; 
}
.footer_left p{
    display: flex;
    width: 50%;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
}
.footer_left p a li{
    list-style: none;
    color: #fff;
}
.footer_right{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footer_right p{
    text-align: right;
}
.footer_right h3{
    font-family: Antonio, sans-serif;
     font-size: clamp(1rem, 2vw , 3rem);
}
.footer_right a{
    text-decoration: none;
}
.footer_right a button{
    background-color: #EA2A91;
    border:none;
    outline:none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;text-decoration: none;
    font-family: Anton, sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 10px;
    font-size: clamp(.8rem, 2vw , 1rem);
    font-weight: 400;
}
.copyright{
    background-color: #540C33;
    color: #fff;
    text-align: center;
    padding-bottom: 15px;
}
.credits{
    width: 100%;
    background-color: #EA2A91;
    text-align: center;
    padding-bottom: 2px;
}
.credits a{
    text-decoration: none;
    color: #fff ;
    width: 100%;
    font-size: clamp(.6rem, 2vw , .8rem);
    
}

@media (max-width:560px){

    .footer_bottom{
    color: #fff;
    background-color: #540C33;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2% 5% 0% 5%;
}

.footer_left{
    justify-content: center;
    width: 100%;
}
.footer_left p {
    width: 100%;
}
.footer_right{
    justify-content: center;
    width: 100%;
}
}